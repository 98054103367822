import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import Navbar from "../Navbar/Navbar";

export default function Tributarios() {
  return (
    <>
      <Navbar />
      <section>
        <div className={styles.cabecera}>
          <div>
            <h1 className={styles.tituloh1}>Consultoria Tributaria</h1>
          </div>
        </div>
        <div className={styles.detallepro}>
          <div className={styles.textoprogen}>
            <div>
              <p>
                Entregamos servicios de cumplimiento de las obligaciones
                tributarias, que buscan asegurar la completa observancia de la
                normativa fiscal por parte de nuestros clientes en las
                diferentes industrias y en los distintos tipos de transacciones
                que efectúen. No nos limitamos únicamente a la preparación y
                presentación de declaraciones, sino que también enviamos “avisos
                tributarios” indicando fechas de cumplimiento que se aproximan.
                Adicionalmente, Taxaccount provee a sus clientes con apoyo ante
                las eventuales fiscalizaciones ante el Servicio de Rentas
                Internas (SRI).
              </p>
              <ul>
                <li>
                  Planificación del Impacto Fiscal.
                </li>
                <li>
                  Preparación y revisión de declaraciones de impuestos IVA,
                  RENTA, ANTICIPOS
                </li>
                <li>Control fiscal periódico mensual, semestral y anual</li>
                <li>
                  Asistencia a la empresa y o personas naturales en notificaciones y requerimientos del
                  SRI
                </li>
                <li>Reclamos Tributarios, devolución de IVA, pagos en exceso y créditos tributarios.</li>
                <li>Elaboración de estrategias tributarias.</li>
                <li>Informes de Cumplimiento Tributario ICT.</li>
                <li>
                  Elaboración de Anexos: ATS, RDEP, GP, ADI, APS, DPT, DEV-IVA.
                </li>
                <li>Gestiones de permisos municipales.</li>
              </ul>
            </div>
          </div>
          <div className={styles.imagenprogen}>
          <img src="../images/tributacion.jpg" alt="Consutoría Tributaria" />
          </div>
        </div>
      </section>
      <section><SeccionUltima /></section>
    </>
  );
}
