import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import Navbar from "../Navbar/Navbar";

export default function Blog() {
  return (
    <>
    <Navbar />
    <section>
      <div className={styles.cabecera}>
        <div>
          <h1 className={styles.tituloh1}>Blog</h1>
        </div>
      </div>
    </section>
    <section><SeccionUltima /></section></>
  );
}
