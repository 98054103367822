import React from "react";
import { Link } from "react-router-dom";
import Formulario from "./formulario";
import styles from "./Inicial.module.css";

export default function SeccionUltima(){
    return (
      <div className={styles.seccionultima}>
        <div className={styles.forma}>
          <Formulario />
        </div>
        <div className={styles.mapasitio}>
          <div className={styles.contenul}>
            <h3>Mapa del sitio</h3>
            <ul className={styles.ulcontmap}>
              <li>
                <Link to="/" className={styles.enlacepie}>Inicio</Link>
              </li>
              <li>
                <Link to="/servicios" className={styles.enlacepie}>Servicios</Link>
              </li>
              <li>
                <Link to="/auditoria" className={styles.enlacepie}>Servicios de Auditoria</Link>
              </li>
              <li>
                <Link to="/contabilidad" className={styles.enlacepie}>Servicios de Contabilidad</Link>
              </li>
              <li>
                <Link to="/laboral" className={styles.enlacepie}>Consultoría Laboral</Link>
              </li>
              <li>
                <Link to="/tributarios" className={styles.enlacepie}>Consultoría Tributaria</Link>
              </li>
              <li>
                <Link to="/societarios" className={styles.enlacepie}>Servicios Societarios</Link>
              </li>
              <li>
                <Link to="/acercade" className={styles.enlacepie}>Acerca de</Link>
              </li>
              <li>
                <Link to="/noticias" className={styles.enlacepie}>Noticias</Link>
              </li>
              <li>
                <Link to="/contacto" className={styles.enlacepie}>Contacto</Link>
              </li>
              <li>
                <Link to="/blog" className={styles.enlacepie}>Blog</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.contacto}>
          <h3>Información de contacto</h3>
            <ul className={styles.ulcont}>
              <li>
                <a href="tel:0997522445"  className={styles.enlacepie}><i class="fa fa-mobile fa-cont" aria-hidden="true"></i> Celular: 0997522445
                </a><br /><br/>
              </li>
              <li>
                <a href="tel:046066410" className={styles.enlacepie}> <i class="fa fa-phone" aria-hidden="true"></i> Oficina: 046066410
                </a><br />
              </li>
              <li>
                <p className={styles.direccion}><i class="fa fa-map-marker" aria-hidden="true"></i> Dirección: Cdla. Vernaza Norte, 4º Pasaje 1 NE, Mz.23 Villa 8 Piso alto</p>
              </li>
              <li>
                <a className={styles.enlacepie}
                href="https://wa.me/593997522445?text=Necesitamos%20información" 
                target="_blank" without rel="noopener noreferrer"><i class="fa fa-whatsapp fa-cont" aria-hidden="true"></i> 0997522445</a>
                <br /><br />
              </li>
              <li>
                <a className={styles.enlacepie}
                href="https://www.linkedin.com/in/jose-del-rosario-b6458b59" 
                target="_blank" without rel="noopener noreferrer"><i class="fa fa-linkedin fa-cont" aria-hidden="true"></i> Linkedin</a>
              </li>
            </ul>
        </div>
      </div>
    );
}
