import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import { Seccion1 } from "../Seccion1";
import Navbar from "../Navbar/Navbar";

export default function Acercade() {
  return (
    <>
    <Navbar />
    <section className={styles.cabecera}>
        <div>
          <h1 className={styles.tituloh1}>Acerca de nosotros</h1>
        </div>
        </section>
        <section>
          <Seccion1 />
        </section>
        <section>
          <SeccionUltima />
        </section>
    </>
  );
}
