import React from "react";

import styles from "./Inicial.module.css";

export function Boton() {
  return (
      <button type="button" className={styles.boton}>
        <a href="/servicios" className={styles.enlaces}>Conozca nuestros servicios</a>
      </button>
  );
}
