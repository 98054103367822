import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import Navbar from "../Navbar/Navbar";

export default function Societarios() {
  return (
    <>
      <Navbar />
      <section>
        <div className={styles.cabecera}>
          <div>
            <h1 className={styles.tituloh1}>Servicios Societarios</h1>
          </div>
        </div>
        <div className={styles.detallepro}>
          <div className={styles.textoprogen}>
            <div>
              <p>
                Te acompañamos desde el inicio de tu proyecto asesorándote sobre las distintas alternativas 
                para la constitución de sociedades analizando sus aspectos estratégicos, legales e impositivos.
                Te explicamos en detalle cuestiones relacionadas con las tareas administrativas, con los
                organismos de control de gobierno y representación, como deben funcionar y cuales son sus obligaciones 
                legales, tributarias y sociales como personas juridicas.
              </p>
              <ul>
                <li>Asesoramiento societario.</li>
                <li>Constitución de Sociedades.</li>
                <li>Asesoría en el cumplimiento de obligaciones societarias ante Super de Compañías.</li>
                <li>Asesoramiento sobre la redacción de contratos societarios.</li>
                <li>Inscripción de las Sociedades.</li>
                <li>Elaboración y actualización de actas (internas y externas).</li>
                <li>Aumentos de Capital.</li>
                <li>Reformas de estatutos, fusión, escisión y liquidación de sociedades.</li>
                <li>Cambios de domicilio</li>
                <li>Dirección y asesoría en juntas generales de socios o accionistas</li>
                <li>Patrocinio y ejecución en sesión de acciones y participaciones.</li>
              </ul>
            </div>
          </div>
          <div className={styles.imagenprogen}>
          <img src="../images/societario.jpg" alt="Servicios Societarios" />
          </div>
        </div>
      </section>
      <section><SeccionUltima /></section>
    </>
  );
}
