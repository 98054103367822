import React from "react";
import styles from "./Seccion.module.css";

export function Seccion1() {
  return (
    <>
      <div className={styles.global}>
        <div className={styles.parte1}>
          <h3 className={styles.parte1h3}>Quienes somos</h3>
          <p>
            Somos un equipo de profesionales que le acompañamos y ayudamos a que
            cumpla sus metas.
            <ul>
              <li>
                Implementamos un sistema de mejoras continuas en conjunto con
                nuestros clientes.
              </li>
              <li>
                Prestamos servicios especiales a compañías que están en proceso
                de desarrollo de sus proyectos, a fin de crecer en conjunto.
              </li>
              <li>
                Asesoramos a nuestros clientes en materias contables,
                tributarias y de remuneraciones aplicadas a la gestión del
                negocio de nuestros clientes, buscando la mayor eficiencia de
                sus procesos.
              </li>
            </ul>
          </p>
        </div>
        <div className={styles.parte2}>
          <img className={styles.image__parte2} src="./handshake.jpg" alt="Taxaccount"></img>
        </div>
      </div>
    </>
  );
}
