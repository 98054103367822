export const menuItems = [
    {
        titulo: 'Inicio',
        url: '/',
        cName: 'navlinks',
        caName: 'active',
        lname: 'Inicio'
    },
    {
        titulo: 'Servicios',
        url: '/servicios',
        cName: 'navlinks',
        caName: 'active',
        lname: 'Servicios',
        submenu: [
            {
                titulo: "Auditoría Financiera y procesos",
                url: '/auditoria',
                cName: 'navlinks',
                caName: 'active',
                lname: 'Auditoría Financiera'
            },
            {
                titulo: "Outsourcing de Contabilidad",
                url: '/contabilidad',
                cName: 'navlinks',
                caName: 'active',
                lname: 'Outsourcing de Contabilidad'
            },
            {
                titulo: "Consultoría Laboral",
                url: '/laboral',
                cName: 'navlinks',
                caName: 'active',
                lname: 'Consutoría Laboral'
            },
            {
                titulo: "Consultoría Tributaria",
                url: '/tributarios',
                cName: 'navlinks',
                caName: 'active',
                lname: 'Consutoría Tributaria'
            },
            {
                titulo: "Servicios Societarios",
                url: '/societarios',
                cName: 'navlinks',
                caName: 'active',
                lname: 'Servicios Societarios'
            }
        ]
    },
    {
        titulo: 'Acerca de',
        url: '/acercade',
        cName: 'navlinks',
        caName: 'active',
        lname: 'Acerca de'
    },
    {
        titulo: 'Noticias',
        url: '/noticias',
        cName: 'navlinks',
        caName: 'active',
        lname: 'Noticias'
    },
    {
        titulo: 'Contacto',
        url: '/contacto',
        cName: 'navlinks',
        caName: 'active',
        lname: 'Contacto'
    },
    {
        titulo: 'Blog',
        url: '/blog',
        cName: 'navlinks',
        caName: 'active',
        lname: 'Blog'
    }
]
