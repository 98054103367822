import React, { Component } from 'react';
import { menuItems } from './menuItems.jsx';
import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";
import './Navbar.css';

class Navbar extends Component {
    state = { clicked: false}

    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }

    render(){
        return(
            <nav>
                <div className="ulcont">
                    <ul>
                        <li>
                            <a href="tel:0997522445"  className="enlacepie">
                            <i class="fa fa-mobile fa-cont" aria-hidden="true"></i> Celular: 0997522445
                            </a>
                        </li>
                        <li>
                            <a href="tel:046066410" className="enlacepie">
                            <i class="fa fa-phone" aria-hidden="true"> </i> Oficina: 046066410
                            </a>
                        </li>
                        <li>
                            <a className="enlacepie"
                            href="https://wa.me/593997522445?text=Necesitamos%20información" 
                            target="_blank" without rel="noopener noreferrer">
                            <i class="fa fa-whatsapp fa-cont" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li>
                            <a className="enlacepie"
                            href="https://www.linkedin.com/in/jose-del-rosario-b6458b59" 
                            target="_blank" without rel="noopener noreferrer">
                            <i class="fa fa-linkedin fa-cont" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="NavbarItems">
                    <Link to="/">
                        <img
                            src="./taxaccount-logo-grande.png"
                            alt="Taxaccount & Asociados"
                            className="imagenlogo">
                        </img>
                    </Link>
                    <div class="menuicon" onClick={this.handleClick}>
                        <i class={this.state.clicked ? 'fa fa-times' : 'fa fa-bars'}></i>
                    </div>
                        <div className="navmenucont">
                            <ul className={this.state.clicked ? 'navmenu:active' : 'navmenu'}>
                                {menuItems.map((menu, index) => {
                                    return <MenuItem items={menu} key={index} />;
                                })}
                            </ul>
                        </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
