/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import Navbar from "./Navbar/Navbar";
import {Servicios} from "./Servicios";
import {Seccion1} from "./Seccion1";
import {Principal} from "./Principal";
import SeccionUltima from "./Seccionultima";

export function App(){
    return (
      <>
        <Navbar />
        <section>
          <Principal />
        </section>
        <section>
          <Seccion1 />
        </section>
        <section>
          <Servicios />
        </section>
        <footer>
          <SeccionUltima />
        </footer>
      </>
    );
  }