import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import Navbar from "../Navbar/Navbar";

export default function Auditoria() {
  return (
    <>
      <Navbar />
      <section>
        <div className={styles.cabecera}>
          <div>
            <h1 className={styles.tituloh1}>Auditoría financiera y procesos</h1>
          </div>
        </div>
        <div className={styles.detallepro}>
          <div className={styles.textoprogen}>
            <p>
              Los servicios de auditoría aportan un punto de vista independiente
              acerca de las prácticas contables y los riesgos de negocio de
              nuestros clientes, promoviendo la transparencia en la información
              financiera que los diversos grupos de interés hoy demandan.
            </p>
            <ul>
              <li>Análisis de Estados Financieros</li>
              <li>Reestructuración de Negocios</li>
              <li>Diseño y Rediseño de Procesos</li>
              <li>Planificación Tributaria Financiera</li>
              <li>Diseño e Implementación de Proyectos</li>
              <li>Control de Gestión</li>
              <li>Liquidación, fusión y adquisición de empresas</li>
              <li>Planes de viabilidad</li>
              <li>Auditoria de estados financieros</li>
              <li>Auditorias operativas</li>
              <li>Revisiones limitadas de estados financieros</li>
              <li>Inventarios físicos</li>
              <li>Informes especiales</li>
              <li>Auditoria laboral</li>
              <li>Auditoria de Procesos Contables</li>
            </ul>
          </div>
          <div className={styles.imagenprogen}>
            <img src="../images/auditoria.jpg" alt="Auditoría Financiera y procesos" />
          </div>
        </div>
      </section>
      <section>
        <SeccionUltima />
      </section>
    </>
  );

}

