import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import Navbar from "../Navbar/Navbar";

export default function Noticias() {
  return (
    <>
    <Navbar />
    <section className={styles.cabecera}>
        <div>
          <h1 className={styles.tituloh1}>Noticias</h1>
        </div>
        </section>
        <section className={styles.seccionew}>
            <div className={styles.detallenew}>
                <div>
                    <div>
                        <h3>SRI</h3>
                    </div>
                    <div>
                        <ul>
                            <li><a className={styles.enlaces} href="https://www.sri.gob.ec/web/intersri/rimpe" 
                            alt="Régimen Simplificado para Emprendedores y Negocios Populares (RIMPE)" 
                            target="_blank" without rel="noopener noreferrer">
                        Régimen Simplificado para Emprendedores y Negocios Populares (RIMPE)</a></li>
                            <li><a className={styles.enlaces} href="https://www.sri.gob.ec/formularios-e-instructivos" 
                            alt="Formularios e instructivos SRI" 
                            target="_blank" without rel="noopener noreferrer">
                        Formularios e instructivos</a></li>
                            <li><a className={styles.enlaces} href="https://www.sri.gob.ec/normativa-tributaria1" 
                            alt="Normativa Tributaria" 
                            target="_blank" without rel="noopener noreferrer">
                        Normativa Tributaria</a></li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div>
                        <h3>Superintendencia de Compañías</h3>
                    </div>
                    <ul>
                        <li><a className={styles.enlaces} href="https://appscvsmovil.supercias.gob.ec/guiasUsuarios/images/guias/societario/cons_sas/SAS.pdf" 
                        alt="Guia de usuario constitución manual SAS"
                        target="_blank" without rel="noopener noreferrer">
                        Guia de usuarios constitución manual de compañías SAS</a></li>
                        <li><a className={styles.enlaces} href="https://appscvsmovil.supercias.gob.ec/guiasUsuarios/images/guias/societario/cons_cesas/CONSTITUCION_ELECTRONICA_SAS.pdf" 
                        alt="Guia de usuario constitución electrónica SAS"
                        target="_blank" without rel="noopener noreferrer">
                        Guia de usuarios constitución electrónica de compañías SAS</a></li>
                    </ul>
                </div>
                <div>
                    <div>
                        <h3>Ministerio de Trabajo</h3>
                    </div>
                    <ul>
                        <li><a className={styles.enlaces} href="https://www.trabajo.gob.ec/wp-content/uploads/2022/03/ACUERDO-SALARIO-DIGNO-2021.pdf?x42051" 
                        alt="Acuerdo Ministerial Nro.MDT-2022-041"
                        target="_blank" without rel="noopener noreferrer">
                        Acuerdo Ministerial Nro.MDT-2022-041, Determinar el valor del salario digno para el año 2021 
                        y regular el procedimiento para el pago de la compensación económica</a></li>
                        <li><a className={styles.enlaces} href="https://www.trabajo.gob.ec/seguridad-salud-en-el-trabajo-y-gestion-integral-de-riesgos/" 
                        alt="Seguridad, Salud en el Trabajo y Gestión Integral de Riesgos"
                        target="_blank" without rel="noopener noreferrer">
                        Seguridad, Salud en el Trabajo y Gestión Integral de Riesgos</a></li>                    
                    </ul>
                </div>
            </div>
            <div className={styles.divenlacesint}>
                <aside className={styles.enlacesint}>
                    <h3>Enlaces de interes</h3>
                    <ul>
                        <li><a href="https://www.sri.gob.ec" 
                        alt="SRI" 
                        target="_blank" without rel="noopener noreferrer">Servicios de rentas internas</a></li>
                        <li><a href="https://www.trabajo.gob.ec/" 
                        alt="Ministerio de Trabajo" 
                        target="_blank" without rel="noopener noreferrer">Ministerio de Trabajo</a></li>
                        <li><a href="https://www.supercias.gob.ec/portalscvs/" 
                        alt="Supercias" 
                        target="_blank" without rel="noopener noreferrer">Superintendencia de Compañías</a></li>
                        <li><a href="https://www.guayaquil.gob.ec/" 
                        alt="Municipio de Guayaquil" 
                        target="_blank" without rel="noopener noreferrer">Municipio de Guayaquil</a></li>
                        <li><a href="https://www.rpguayaquil.gob.ec/" 
                        alt="Registrador de la propiedad" 
                        target="_blank" without rel="noopener noreferrer">Registrador de la Propiedad de Guayaquil</a></li>
                        <li><a href="https://www.bomberosguayaquil.gob.ec/" 
                        alt="Cuerpo de bomberos Guayaquil" 
                        target="_blank" without rel="noopener noreferrer">Cuerpo de Bomberos Guayaquil</a></li>
                    </ul>
                </aside>
            </div>
        </section>
        <section>
          <SeccionUltima />
        </section>
    </>
  );
}