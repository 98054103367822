import React from "react";
import styles from "../Contacto.module.css";
import Formulario from "../formulario";
import Navbar from "../Navbar/Navbar";

export default function Contacto() {
  return (
    <>
      <Navbar />
      <section className={styles.cabecera}>
        <div>
          <h1 className={styles.tituloh1}>Contáctenos</h1>
        </div>
      </section>
      <section className={styles.gmapcontacto}>
        <div className={styles.gmap}>
          <img src="https://maps.googleapis.com/maps/api/staticmap?center=-2.1536847,-79.8934881&zoom=17&size=600x400&markers=color:red%7Clabel:T%7C-2.1536847,-79.89348818&key=AIzaSyAX7XSQ3K94BsJxCEZ-AXtKWKRDtrtlFRk&signature=TDiGLaZMFGez-HmOfkqmurBnHYo="
          alt="Taxaccount & Asociados" />
        </div>
        <aside className={styles.acontacto}>
          <div className={styles.dcontacto}>
            <h3>Información de contacto</h3>
            <ul className={styles.ulcont}>
              <li>
                <a href="tel:0997522445"  className={styles.enlacepie}><i class="fa fa-mobile fa-cont" aria-hidden="true"></i> Celular: 0997522445
                </a><br /><br/>
              </li>
              <li>
                <a href="tel:046066410" className={styles.enlacepie}> <i class="fa fa-phone" aria-hidden="true"></i> Oficina: 046066410
                </a><br />
              </li>
              <li>
                <p className={styles.direccion}><i class="fa fa-map-marker" aria-hidden="true"></i> Dirección: Cdla. Vernaza Norte, 4º Pasaje 1 NE, Mz.23 Villa 8 Piso alto</p>
              </li>
              <li>
                <a className={styles.enlacepie}
                href="https://wa.me/593997522445?text=Necesitamos%20información" 
                target="_blank" without rel="noopener noreferrer"><i class="fa fa-whatsapp fa-cont" aria-hidden="true"></i> 0997522445</a>
                <br /><br />
              </li>
              <li>
                <a className={styles.enlacepie}
                href="https://www.linkedin.com/in/jose-del-rosario-b6458b59" 
                target="_blank" without rel="noopener noreferrer"><i class="fa fa-linkedin fa-cont" aria-hidden="true"></i> Linkedin</a>
              </li>
            </ul>
          </div>
        </aside>
      </section>
      <section><Formulario /></section>
    </>
  );
}