import React from "react";
import styles from "./servicios.module.css";

export function Servicios() {
  return (
      <>
        <div className={styles.serviciosTitulo}>
          <h3>Nuestros Servicios</h3>
        </div>
        <ul className={styles.serviciosGrid}>
          <li className={styles.serviciosLista}>
            <a className={styles.enlaces} href="/auditoria">
              <div className={styles.auditoria}>
              </div>
            </a>
            <div className={styles.tarjeta}>
              <h3 className={styles.tituloservicio}>
                Auditoria financiera y procesos
              </h3>
              <p>Los servicios de auditoría aportan un punto de vista independiente
                acerca de las prácticas contables y los riesgos de negocio</p>
                <a className={styles.enlaces} href="/auditoria">Leer más ==> </a>
            </div>
          </li>
          <li className={styles.serviciosLista}>
            <a className={styles.enlaces} href="/contabilidad">
            <div className={styles.contabilidad}>
            </div>
            </a>
            <div className={styles.tarjeta}>
              <h3 className={styles.tituloservicio}>
                Outsourcing de Contabilidad
              </h3>
              <p>Servicios de Contabilidad para Empresas y Personas Naturales. 
                Nuestra meta es generar información financiera de calidad 
                a nuestros clientes</p>
                <a className={styles.enlaces} href="/contabilidad">Leer más ==></a>
            </div>
          </li>
          <li className={styles.serviciosLista}>
            <a className={styles.enlaces} href="/laboral">
              <div className={styles.laboral}>
              </div>
            </a>
            <div className={styles.tarjeta}>
              <h3 className={styles.tituloservicio}>
                Consultoría Laboral
              </h3>
              <p>No sólo las situaciones de crisis o de caos financiero suponen 
                la contratación de un servicio de consultoría financiera. También los
              entornos favorables</p>
                <a className={styles.enlaces} href="/laboral">Leer más ==></a>
            </div>
          </li>
          <li className={styles.serviciosLista}>
            <a className={styles.enlaces} href="/tributarios">
              <div className={styles.tributacion}>
              </div>
            </a>
            <div className={styles.tarjeta}>
              <h3 className={styles.tituloservicio}>
                Consultoría Tributaria
              </h3>
              <p>Entregamos servicios de cumplimiento de las obligaciones tributarias, 
                que buscan asegurar la completa observancia de la normativa</p>
                <a className={styles.enlaces} href="/tributarios">Leer más ==></a>
            </div>
          </li>
          <li className={styles.serviciosLista}>
            <a className={styles.enlaces} href="/societarios">
              <div className={styles.societario}>
              </div>
            </a>
            <div className={styles.tarjeta}>
              <h3 className={styles.tituloservicio}>
                Servicios Societarios
              </h3>
              <p>Te acompañamos desde el inicio de tu proyecto asesorándote sobre las distintas 
                alternativas para la constitución de sociedades.</p>
                <a className={styles.enlaces} href="/societarios">Leer más ==> </a>
            </div>
          </li>      
        </ul>
      </>
  );
}
