/* eslint-disable jsx-a11y/heading-has-content */

import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import { Layout } from "./Layout";
import Acercade from "./routes/acercade.jsx";
import Serviciostax from "./routes/serviciostax.jsx";
import Auditoria from "./routes/auditoria.jsx";
import Contabilidad from "./routes/contabilidad.jsx";
import Laboral from "./routes/laboral.jsx";
import Tributarios from "./routes/tributarios.jsx";
import Societarios from "./routes/societarios.jsx";
import Noticias from "./routes/noticias.jsx";
import Contacto from "./routes/contacto.jsx";
import Blog from "./routes/Blog.jsx";

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<App />} />
        <Route path="/acercade" element={<Acercade />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/servicios" element={<Serviciostax />} />
        <Route path="/auditoria" element={<Auditoria />} />
        <Route path="/contabilidad" element={<Contabilidad />} />
        <Route path="/laboral" element={<Laboral />} />
        <Route path="/tributarios" element={<Tributarios />} />
        <Route path="/societarios" element={<Societarios />} />
        <Route path="/noticias" element={<Noticias />} />
        <Route path="/contacto" element={<Contacto />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);
