import React from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom'
import Dropdown from "./Dropdown";

const MenuItem = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);

    return (
        <>
        {items.submenu ? (
            <>
                <li className="navmenuli">
                    <NavLink to={items.url}
                    onMouseOverCapture = {() => setDropdown((prev) => !prev)} exact={true} activeClassName={items.caName} class={items.cName}>
                    {items.titulo}</NavLink>
                    <Dropdown submenus={items.submenu} dropdown={dropdown}/>
                </li>
            </>
        ) : (
            <>
            <li className="navmenuli">
                <NavLink to={items.url} exact={true} activeClassName={items.caName} class={items.cName}>{items.titulo}</NavLink>
            </li>
            </>
        )}
    </>
    );
};

export default MenuItem;
