/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import styles from "./Inicial.module.css";
import {Boton} from "./Boton";


export function Principal(){
    return (
      <div className={styles.contenedor}>
        <div className={styles.tituloh1}>
          <h1>TAXACCOUNT & ASOCIADOS</h1>
        </div>
        <div className={styles.tituloh2}>
          <h2>Auditores, Contadores y Consultores</h2>
        </div>
        <div>
          <Boton />
        </div>

      </div>
    );
}