import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import Navbar from "../Navbar/Navbar";

export default function Contabilidad() {
  return (
    <>
      <Navbar />
      <div className={styles.cabecera}>
        <div>
          <h1 className={styles.tituloh1}>Outsourcing de Contabilidad</h1>
        </div>
      </div>
      <section>
        <div className={styles.detallepro}>
          <div className={styles.textoprogen}>
            <p>
              Servicios de Contabilidad para Empresas y Personas Naturales.
              Nuestra meta es generar información financiera de calidad a
              nuestros clientes, información contable de acuerdo a regulaciones
              aplicables en Ecuador y preparación e interpretación de Estados
              Financieros bajo Normas Internacionales de Información Financiera
              (NIIF-IFRS), que permitan tomar buenas decisiones de negocio a
              nuestros clientes.
            </p>
            <ul>
              <li>Implementación de sistemas contables.</li>
              <li>Elaboración de presupuestos.</li>
              <li>Conciliaciones inter-compañías</li>
              <li>Contabilidad de activos fijos</li>
              <li>Reportes financieros y de administración</li>
              <li>Manejo de Nomina de Empleados</li>
              <li>Cierre financiero y consolidación</li>
              <li>Reportes financieros y de administración</li>
              <li>Desarrollo de normas del plan general de contabilidad</li>
              <li>Aplicación de los criterios contables de valoración</li>
              <li>Realización de asientos puntuales durante el ejercicio</li>
              <li>Estados financieros mensuales</li>
              <li>Atención permanente a consultas (e-mail, teléfono)</li>
              <li>Supervisión Contable</li>
              <li>Toma de inventarios físicos de activos fijos y mercaderías</li>
            </ul>
          </div>
          <div className={styles.imagenprogen}>
            <img src="../images/contabilidad.jpg" alt="Outsourcing de Contabilidad" />
          </div>
        </div>
      </section>
      <section>
        <SeccionUltima />
      </section>
    </>
  );
}
