import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import Navbar from "../Navbar/Navbar";

export default function Serviciostax() {
  return (
    <>
      <Navbar />
      <section className={styles.cabecera}>
        <div>
          <h1 className={styles.tituloh1}>Nuestros Servicios</h1>
        </div>
      </section>
      <section>
        <div className={styles.detallepro}>
          <div className={styles.textopro}>
            <h3>Auditoría financiera y procesos</h3>
            <p>
              Los servicios de auditoría aportan un punto de vista independiente
              acerca de las prácticas contables y los riesgos de negocio de
              nuestros clientes, promoviendo la transparencia en la información
              financiera que los diversos grupos de interés hoy demandan.
            </p>
            <a className={styles.enlaces} href="/auditoria">Leer más ==> </a>
          </div>
          <div className={styles.imagenpro}>
            <img src="../images/auditoria.jpg" alt="Auditoría Financiera y procesos" />
          </div>
        </div>
      </section>
      <section>
        <div className={styles.detallepro2}>
          <div className={styles.imagenpro}>
            <img src="../images/contabilidad.jpg" alt="Outsourcing de Contabilidad" />
          </div>
          <div className={styles.textopro2}>
            <h3>Outsourcing de contabilidad</h3>
            <p>
              Servicios de Contabilidad para Empresas y Personas Naturales.
              Nuestra meta es generar información financiera de calidad a
              nuestros clientes, información contable de acuerdo a regulaciones
              aplicables en Ecuador y preparación e interpretación de Estados
              Financieros bajo Normas Internacionales de Información Financiera
              (NIIF-IFRS), que permitan tomar buenas decisiones de negocio a
              nuestros clientes.
            </p>
            <a className={styles.enlaces} href="/contabilidad">Leer más ==></a>
          </div>
        </div>
      </section>
      <section>
        <div className={styles.detallepro}>
          <div className={styles.textopro}>
            <h3>Consultoría Laboral</h3>
            <p>
              No sólo las situaciones de crisis o de caos financiero suponen la
              contratación de un servicio de consultoría financiera. También los
              entornos favorables pueden demandar este tipo de orientación,
              independientemente del tipo de empresa, de su actividad comercial
              o del modelo de financiación en el cual se sustenta.
            </p>
            <a className={styles.enlaces} href="/laboral">Leer más ==></a>
          </div>
          <div className={styles.imagenpro}>
            <img src="../images/laboral.jpg" alt="Consutoría Laboral" />
          </div>
        </div>
      </section>
      <section>
        <div className={styles.detallepro2}>
          <div className={styles.imagenpro}>
            <img src="../images/tributacion.jpg" alt="Consutoría Tributaria" />
          </div>
          <div className={styles.textopro2}>
            <h3>Consultoría Tributaria</h3>
            <p>
              Entregamos servicios de cumplimiento de las obligaciones
              tributarias, que buscan asegurar la completa observancia de la
              normativa fiscal por parte de nuestros clientes en las diferentes
              industrias y en los distintos tipos de transacciones que efectúen.
              No nos limitamos únicamente a la preparación y presentación de
              declaraciones, sino que también enviamos “avisos tributarios”
              indicando fechas de cumplimiento que se aproximan. Adicionalmente,
              Taxaccount provee a sus clientes con apoyo ante las eventuales
              fiscalizaciones ante el Servicio de Rentas Internas (SRI).
            </p>
            <a className={styles.enlaces} href="/tributarios">Leer más ==></a>
          </div>
        </div>
      </section>
      <section>
        <div className={styles.detallepro}>
          <div className={styles.textopro}>
            <h3>Servicios Societarios</h3>
            <p>
              Te acompañamos desde el inicio de tu proyecto asesorándote sobre las distintas alternativas 
              para la constitución de sociedades analizando sus aspectos estratégicos, legales e impositivos.
              Te explicamos en detalle cuestiones relacionadas con las tareas administrativas, con los
              organismos de control de gobierno y representación, como deben funcionar y cuales son sus obligaciones 
              legales, tributarias y sociales como personas juridicas.
            </p>
            <a className={styles.enlaces} href="/societarios">Leer más ==></a>
          </div>
          <div className={styles.imagenpro}>
            <img src="../images/societario.jpg" alt="Servicios Societarios" />
          </div>
        </div>
      </section>      
      <section><SeccionUltima /></section>
    </>
  );
}
