import React from "react";
import styles from "../Productos.module.css";
import SeccionUltima from "../Seccionultima";
import Navbar from "../Navbar/Navbar";

export default function Laboral() {
  return (
    <>
      <Navbar />
      <div className={styles.cabecera}>
        <div>
          <h1 className={styles.tituloh1}>Consultoria Laboral</h1>
        </div>
      </div>
      <div className={styles.detallepro}>
        <div className={styles.textoprogen}>
          <p>
            No sólo las situaciones de crisis o de caos financiero suponen la
            contratación de un servicio de consultoría financiera. También los
            entornos favorables pueden demandar este tipo de orientación,
            independientemente del tipo de empresa, de su actividad comercial o
            del modelo de financiación en el cual se sustenta.
          </p>
          <ul>
            <li>Análisis de Estados Financieros</li>
            <li>Reestructuración de Negocios</li>
            <li>Diseño y Rediseño de Procesos</li>
            <li>Planificación Tributaria Financiera</li>
            <li>Diseño e Implementación de Proyectos</li>
            <li>Control de Gestión</li>
            <li>Liquidación, fusión y adquisición de empresas</li>
            <li>Planes de viabilidad</li>
          </ul>
        </div>
        <div className={styles.imagenprogen}>
          <img src="../images/laboral.jpg" alt="Consutoría Laboral" />
        </div>
      </div>
      <section><SeccionUltima /></section>
    </>
  );
}
