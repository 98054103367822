import React from 'react';
import styles from "./Contacto.module.css";

export default function Formulario(){
    return(
        <>
            <section>
                <div className={styles.forma}>
                    <h3>Para mayor información por favor complete el siguiente formulario</h3>
                    <form action="https://formsubmit.co/jdr-contador-2005@hotmail.com" name="forma" autocomplete="off" method="POST">
                    <input type="hidden" name="_template" value="table" />
                    <input type="hidden" name="_next" value="https://taxaccount.ec" />
                    <div className={styles.linea}>
                        <div className={styles.inputfields6}>
                        <label htmlFor="nombres">Nombres</label>
                        <input type="text" id="nombres" required/>
                        </div>
                        <div className={styles.inputfields6}>
                        <label htmlFor="correo">Email</label>
                        <input type="email" id="correo" required/>
                        </div>
                    </div>
                    <div className={styles.linea}>
                        <div className={styles.inputfields6}>
                        <label htmlFor="telefono">Telefono</label>
                        <input type="tel" id="telefono" required/>
                        </div>
                        <div className={styles.inputfields6}>
                        <label htmlFor="detalle">Observación</label>
                        <textarea id="detalle" autocomplete="off" required/>
                        </div>
                    </div>
                    <div>
                        <button className={styles.botonforma} type="submit" id="boton">Enviar</button>
                    </div>
                    </form>
                </div>
            </section>
        </>
    );
}