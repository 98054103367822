import React from 'react';
import { NavLink } from 'react-router-dom';


const Dropdown = ({ submenus, dropdown }) => {
    return (
    <div className="ulsubmenu">
     <ul className={`dropdown${dropdown ? ":active" : ""}`}>
      {submenus.map((submenu, index) => (
          <li key={index} className="listasubm">
              <NavLink to={submenu.url}>{submenu.titulo}</NavLink>
            </li>
      ))}
     </ul>
     </div>
    );
   };
   
   export default Dropdown;